import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  GetSatelliteLookupResponse,
  GetUrlLookupResponse,
} from "app/models/Provisioner/Provisioner";
import { environment } from "environments/environment";
import { catchError, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProvisionerService {
  constructor(private httpClient: HttpClient) {}

  getUrlLookups() {
    const url =
      environment.appConfig.provisionerUrl + "/v2/docs/lookup/hubAppUrl";

    return this.httpClient
      .get<GetUrlLookupResponse>(url, {
        headers: { Authorization: sessionStorage.getItem("authToken") },
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return of(null);
        })
      );
  }

  getSatelliteLookup() {
    const url =
      environment.appConfig.provisionerUrl + "/v2/docs/lookup/satellite";

    return this.httpClient
      .get<GetSatelliteLookupResponse>(url, {
        headers: { Authorization: sessionStorage.getItem("authToken") },
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return of(null);
        })
      );
  }
}
