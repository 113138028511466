import { Subscription } from "rxjs";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "./../../../../environments/environment";
import { BlockerService } from "./../../../services/blocker.service";
import { UserInfoService } from "./../../../services/user-info.service";
import { SSOService } from "../../../services/sso.service";
import { TranslocoService } from "@jsverse/transloco";
import { NotificationService } from "../../../services/notifications.service";
import { MatMenuTrigger } from '@angular/material/menu';
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog } from '@angular/material/dialog';
import { UserDetailsComponent } from '../../user-details/user-details.component';
import { AppComponent } from "app/app.component";

@Component({
  selector: "main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.scss"]
})
export class MainNavComponent implements OnInit, OnDestroy {
  userName: string;
  firstName: string;
  givenName: string;
  IsFederatedAccountUser: boolean;
  localEnvironment: any;

  isLoggedIn = false;
  firstLoad = false;
  isMenuOpen: boolean = false;
  ssoLoginEndpoint: string;
  ssoLogoutEndpoint: string;

  @ViewChild('userMenuTrigger') userMenuTrigger: MatMenuTrigger;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private blockerService: BlockerService,
    private ssoService: SSOService,
    public translocoService: TranslocoService,
    public notification: NotificationService,
    public dialog: MatDialog,
    public appComponent: AppComponent
  ) {
    this.localEnvironment = environment;
  }

  ngOnInit() {
    this.ssoService.tokenValidation().subscribe((res) => {
      if (sessionStorage.getItem('authToken') !== null && !(res instanceof HttpErrorResponse)) {
        this.getAppConfigurations();
        this.subscribeToUserNames();
        this.appComponent.initiateApp();
      } 
    })
    this.ssoService.isLoggedIn$.subscribe((res) => {
      this.isLoggedIn = res;
    })
  }

  openUserDetails() {
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      width: '777px',
      height: '777px'
    });
  }

  bake_cookie(name, value) {
    var now = new Date();
    now.setMonth(now.getMonth() + 4);
    var cookie = [name, '=', JSON.stringify(value), `; expires=${now.toUTCString()}`].join('');
    document.cookie = cookie;
  }

  read_cookie(name) {
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    if (result && result['lang'] !== this.translocoService.getActiveLang()) {
      this.changeLanguage(result['lang']);
    } else {
      this.changeLanguage('en');
    }
    return result;
  }

  changeLanguage(arg, event?) {
    this.translocoService.setActiveLang(arg);
    this.bake_cookie('userSettings', { lang: arg });
    if (event) {
      this.notification.showSuccess('Language set to ' + event, null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  waitForDrawer(id, callback) {
    var poops = setInterval(function () {
      if (document.getElementById(id)) {
        clearInterval(poops);
        callback();
      }
    }, 100);
  }

  getAppConfigurations() {
    this.ssoService.getAppConfigs().subscribe((res) => {
      if (res?.applications && !(res instanceof HttpErrorResponse)) {
        const localThis = this;
        this.waitForDrawer('myDrawer', function () {
          localThis.generateAppObject(res.applications);
        });
      }
    });
  }

  subscribeToUserNames() {
    this.userName = this.userInfoService.getUserName();
    this.firstName = this.userInfoService.getFirstName();
    this.givenName = this.userInfoService.getGivenName();
    this.IsFederatedAccountUser =
      this.userInfoService.getUserFederatedAccountInfo();
    if (this.isLoggedIn && !this.firstLoad) {
      this.firstLoad = true;
      this.read_cookie('userSettings');
    }
  }

  generateAppObject(arg) {
    const entitlementAry = [];
    arg.forEach((application) => {
      let obj = {
        name: "",
        displayName: "",
        documentationUrl: "",
        productImage: "",
        environments: [],
        selectedEnv: "",
        selectedEnvWebLink: ""
      };
      if (application.environments.length > 0) {
        obj.name = application.ui_configs.name;
        obj.displayName = application.ui_configs.displayName;
        obj.productImage = application.ui_configs.productImage;
        obj.documentationUrl = application.ui_configs.documentationUrl;
        obj.environments = application.environments;
        obj.selectedEnv = application.environments[0].env_name;
        obj.selectedEnvWebLink = application.environments[0].web_link;
        entitlementAry.push(obj);
      }
    });
    this.prepareDataToDrawer(entitlementAry);
  }

  prepareDataToDrawer(arg) {
    const arrayToDrawer = [];
    arg.forEach(element => {
      if (element.environments.length > 1) {
        element.environments.forEach(env => {
          let obj = {
            name: element.displayName,
            subtitle: env.env_name,
            iconUrl: environment.appConfig.staticContent + '/product-icons/' + element.productImage,
            url: null
          }
          obj.url = env.web_link;
          arrayToDrawer.push(obj);
        });
      } else {
        let obj = {
          name: element.displayName,
          iconUrl: environment.appConfig.staticContent + '/product-icons/' + element.productImage,
          url: element.environments[0].web_link
        }
        arrayToDrawer.push(obj);
      }
    });
    const bankingPortalProducts = {
      order: 1,
      url: environment.appConfig.bankingPortalEndpoint.url,
      name: 'Banking Portal',
      initials: 'BP',
      tooltip: 'My banking applications',
      iconUrl: null,
      isParent: true,
      children: arrayToDrawer
    };
    let el = document.getElementById('myDrawer');
    el.setAttribute('customproducts', JSON.stringify([bankingPortalProducts]));
    el.setAttribute('userid', this.userInfoService._decodedToken.email);
    el.setAttribute('eventid', this.userInfoService._decodedToken.eventId);
  }

  public onMenuClick(): void {
    this.isMenuOpen = false;
  }

  navigateToMA() {
    window.open("https://www.moodysanalytics.com/");
  }

  triggerFalseClick(event: any) {
    event.preventDefault();
    event.target.click();
  }

}


