import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Params } from "@angular/router";
import { trigger, transition, style, animate } from "@angular/animations";
import { environment } from "./../environments/environment";
import { SSOService } from "./services/sso.service";
import { UserInfoService } from "./services/user-info.service";
import { ProjectWorkspaceService } from "./services/project-workspace.service";
import { filter } from "rxjs/operators";
import { NotificationService } from "./services/notifications.service";
import { IsLoadingService } from "@service-work/is-loading";
import { Observable } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { ScriptsService } from "./scripts/scripts.service";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("routeAnimation", [
      transition(":enter", []),

      transition("* => loginPage", [
        style({ transform: "translate(0px, 400px)" }),
        animate("300ms", style({ transform: "translate(0px)" })),
      ]),

      transition("* => logoutPage", [
        style({ transform: "translate(0px, 400px)" }),
        animate("300ms", style({ transform: "translate(0px)" })),
      ]),
    ]),
    trigger("routeSubNavAnimation", [
      transition(":enter", []),

      transition("* => *", [
        style({ transform: "translate(0px, -200px)" }),
        animate("300ms", style({ transform: "translate(0px)" })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  showFullScreen = false;
  totalNavHeight;
  screenHeight;
  hideProductsNav = false;
  onBenchmarkPage = false;
  isMultiorgSet = false;
  @ViewChild("products") products;
  isBlockerEnabled: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userInfoService: UserInfoService,
    private ssoService: SSOService,
    public projectWorkspaceService: ProjectWorkspaceService,
    public notification: NotificationService,
    public isLoadingService: IsLoadingService,
    public scripts: ScriptsService,
    private readonly viewport: ViewportScroller,
  ) {
    router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((val) => {
        if (val instanceof NavigationEnd === true) {
          if (
            this.router.url.indexOf("/login") >= 0 ||
            this.router.url.indexOf("/federatedlogin") >= 0 ||
            this.router.url.indexOf("/logout") >= 0
          ) {
            this.showFullScreen = true;
          } else {
            if (this.router.url.indexOf("/account") >= 0) {
              this.hideProductsNav = true;
            } else {
              this.showFullScreen = false;
              this.hideProductsNav = false;

              if (this.userInfoService.getForceResetPassword()) {
                this.router.navigate(["./account"]);
              }
            }
          }
        }
        if (val instanceof NavigationStart) {
          return;
        }
      });

    this.router.events.subscribe((val) => {
      window.setTimeout(() => {
        this.adjustBodyPadding();
      }, 0);
      if (val instanceof NavigationEnd === true) {
        if (this.router.url.indexOf("/benchmark") >= 0) {
          this.onBenchmarkPage = true;
        } else {
          this.onBenchmarkPage = false;
        }
      }
    });

    this.scripts
      .load("Sisense SDK", "Gateway Drawer", "ODI", "User Detail")
      .then((data) => { })
      .catch((error) => console.log(error));
  }
  ngOnInit(): void {
    this.isBlockerEnabled = this.isLoadingService.isLoading$();
    if (window.location.href.includes('referrerurl')) {
      sessionStorage.removeItem('uuid');
    }
    //If user is coming from logout screen skip getting token
    if (!window.location.href.includes("logout")) {
      this.ssoService.tokenValidation().subscribe((res) => {
        if (sessionStorage.getItem('authToken') !== null && !(res instanceof HttpErrorResponse) && !window.location.href.includes('federatedlogin')) {
          this.userInfoService.setAuthToken(sessionStorage.getItem('authToken'));
          // this.initiateApp();
        }
      })
    }
  }

  initiateApp() {
    this.refreshToken();
    this.userInfoService.addHeapIdentifyAPI();
    const productReferrerUrl = localStorage.getItem("app-url");
    if (productReferrerUrl && productReferrerUrl.indexOf("benchmark") > -1) {
      this.restoreQueryParam();
    } else if (window.location.pathname == "/account") {
      this.router.navigate(["./account"]);
    }
  }

  refreshToken() {
    const refreshTime = environment.appConfig.tokenRefreshInterval;
    // clear if any existing refresh Interval exists
    clearInterval(this.ssoService.refreshRef);
    // set a new interval
    this.ssoService.refreshRef = setInterval(() => {
      if (sessionStorage.getItem('authToken')) {
        // set the latest token
        this.userInfoService.setAuthToken(sessionStorage.getItem('authToken'));
        // check if the token will expire in two minutes
        if (this.userInfoService.isTokenAboutToExpire()) {
          // renew the token
          this.ssoService.renewAuthToken().subscribe((newAuthToken: any) => {
            // check if the response is valid
            if (newAuthToken && !(newAuthToken instanceof HttpErrorResponse)) {
              // set the latest token (via the service)
            }
          });
        }
      }
    }, refreshTime);
  }

  ngAfterViewInit(): void {
    this.adjustBodyPadding();
  }

  ngAfterContentInit(): void {
    this.screenHeight = window.innerHeight;
  }

  prepRouteState(outlet: any) {
    return outlet.activatedRouteData["animation"] || "homePage";
  }

  onResize() {
    this.adjustBodyPadding();
  }

  adjustBodyPadding() {
    this.screenHeight = window.innerHeight;
    // If screen is under 640px, navbar is not fixed, so do not add padding to body to adjust for navbar height
    if (window.innerWidth < 640) {
      this.totalNavHeight = 0;
    } else {
      // 50 comes from the height of the top blue navbar element always having height of 50px
      this.totalNavHeight = 50;
    }
  }

  restoreQueryParam() {
    let dashQueryParams = window.location.href;
    if (dashQueryParams.includes("/benchmark?")) {
      dashQueryParams = dashQueryParams.split("?")[1];
      let dashName = dashQueryParams.split("=")[0].replace(/%20/g, " ");
      let dashId = dashQueryParams.split("=")[1];
      this.router.navigate(["./benchmark"], {
        queryParams: { [dashName]: dashId },
      });
    } else {
      this.router.navigate(["./benchmark"]);
    }
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }
}
function ObservableEmpty(): any {
  throw new Error("Empty observable");
}
