import { tap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError as ObservableThrowError, empty as ObservableEmpty, of } from 'rxjs';
import { NotificationService } from '../services/notifications.service';
import { environment } from '../../environments/environment';
import { UserInfoService } from './user-info.service';
import { BlockerService } from './blocker.service';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { GetTenantSitesResponse } from 'app/models/SSO/entitlement';

@Injectable()
export class SSOService {
  private ssoServiceEndpoint: string;
  private userCodeEndpoint: string;
  private authTokenEndpoint: string;
  private renewTokenEndpoint: string;
  private changePasswordEndpoint: string;
  private ssoTokenEndpoint: string;
  private deleteTokenEndpoint: string;
  appConfigEndpoint: string;
  public refreshRef;
  public isLoggedIn$ = new BehaviorSubject<any>(false);

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private userInfoService: UserInfoService,
    private blockerService: BlockerService,
    private isLoadingService: IsLoadingService,
    private notification: NotificationService
  ) {
    this.ssoServiceEndpoint = environment.appConfig.ssoServiceEndpoint;
    this.userCodeEndpoint = '/auth/login';
    this.authTokenEndpoint = '/auth/gettoken/';
    this.renewTokenEndpoint = '/auth/renewtoken';
    this.ssoTokenEndpoint = '/auth/ssotoken';
    this.changePasswordEndpoint = '/user/changepassword';
    this.deleteTokenEndpoint = '/v1/token';
    this.appConfigEndpoint = '/v2/apps';
  }

  initiateLogin() {
    sessionStorage.clear();
    // localStorage.setItem('isLogin', 'true');
    if (window.location.href.indexOf("benchmark") !== -1) {
      this.router.navigate(["./federatedlogin"], {
        queryParams: { referrerurl: window.location.href },
      });
    } else {
      //If no benchmark route, after login land on my applications page
      this.router.navigate(["./federatedlogin"], {
        queryParams: { referrerurl: window.location.origin + "/applications" },
      });
    }
  }

  initiateLogout() {
    // localStorage.setItem('isLogin', 'false');
    this.router.navigate(["./logout"], {
      queryParams: { redirectUrl: window.location.origin },
    });
  }

  /*Redirect to WSO2 Login to get user code*/
  public getUserCode(ssoVersion?: number, queryParamfdid?: string, queryParamUuid?: string) {
    if (queryParamfdid) {
      window.location.href = this.ssoServiceEndpoint + '/v1/' + queryParamfdid + '/login' + '?scope=openid ' + queryParamUuid;
    } else {
      if (queryParamUuid !== undefined && queryParamUuid !== null) {
        window.location.href = this.ssoServiceEndpoint + this.versionEndpoint(ssoVersion, this.userCodeEndpoint) + '?scope=openid ' + queryParamUuid;
      } else {
        window.location.href = this.ssoServiceEndpoint + this.versionEndpoint(ssoVersion, this.userCodeEndpoint);
      }
    }
  }

  /*Get SSO Authorization Token*/
  public getAuthTokenHeaders(userCode: string, fdid: string, ssoVersion?: number, uuid?: string): Observable<HttpHeaders> {
    const url = this.ssoServiceEndpoint + this.versionEndpoint(ssoVersion, this.authTokenEndpoint);
    const fdidString = fdid && fdid.length > 0 ? '?fdid=' + fdid : '';
    const uuidString = uuid && uuid.length > 0 ? '?scope=openid ' + uuid : '';
    // responseType set to 'text' since the blank body in the response causes an error on IE
    return this.httpClient.get(url + userCode + uuidString + fdidString, { observe: 'response', responseType: 'text', withCredentials: true }).pipe(
      map(response => response.headers),
      catchError(this.handleError)
    );
  }

  public ssoToken() {
    this.isLoadingService.add();
    const url = this.ssoServiceEndpoint + this.ssoTokenEndpoint;
    return this.httpClient
      .get(url, {
        observe: 'response',
        responseType: 'text',
        withCredentials: true
      })
      .pipe(
        map(response => response.headers.get('Authorization')),
        tap((res) => {
          this.isLoadingService.remove();
        }),
        catchError((error) => {
          this.isLoadingService.remove();
          return of(error);
        })
      );
  }

  public tokenValidation() {
    this.isLoadingService.add();
    const url = environment.appConfig.ssoTokenValidation + '/token';
    return this.httpClient.get(
      url, {
      headers: new HttpHeaders().set('Authorization', sessionStorage.getItem('authToken'))
    }).pipe(
      tap((res) => {
        this.isLoadingService.remove();
        this.isLoggedIn$.next(true);
      }),
      catchError((error) => {
        this.isLoadingService.remove();
        this.isLoggedIn$.next(false);
        return of(error);
      })
    );
  }

  public renewAuthToken(): Observable<string> {
    const url = this.ssoServiceEndpoint + this.renewTokenEndpoint;
    return this.httpClient
      .get(url, {
        observe: 'response',
        responseType: 'text',
        headers: new HttpHeaders().set('Authorization', sessionStorage.getItem('authToken')),
        withCredentials: true
      })
      .pipe(
        map(response => response.headers.get('Authorization')),
        tap((res) => {
          sessionStorage.setItem('authToken', res);
          this.userInfoService.setAuthToken(res);
        }),
        catchError(error => {
          this.isLoadingService.remove();
          this.initiateLogout();
          return of(error);
        })
      );
  }

  public deleteToken() {
    const url = this.ssoServiceEndpoint + this.deleteTokenEndpoint;
    return this.httpClient
      .delete(url, {
        observe: 'response',
        responseType: 'text',
        headers: new HttpHeaders().set('Authorization', sessionStorage.getItem('authToken')),
        // withCredentials: true,
        // params: redirectUrl ? new HttpParams().set('redirectUrl', redirectUrl) : {}
      })
      .pipe(
        map(response => response.body),
        tap((res) => {
          this.isLoggedIn$.next(false);
        }),
        catchError(this.handleError)
      );
  }

  public changePassword(changePasswordForm) {
    const url = this.ssoServiceEndpoint + this.changePasswordEndpoint;
    return this.httpClient
      .put(
        url,
        {
          currentPassword: changePasswordForm.currentPassword,
          newPassword: changePasswordForm.newPassword
        },
        {
          observe: 'response',
          responseType: 'text',
          headers: new HttpHeaders().set('Authorization', sessionStorage.getItem('authToken'))
        }
      )
      .pipe(
        tap((res) => {
          this.isLoadingService.remove();
        }),
        catchError((error) => {
          this.isLoadingService.remove();
          return of(error);
        })
      );
  }

  public getAppConfigs() {
    this.isLoadingService.add();
    let url = environment.appConfig.multiEnvEndpoint + '/v2/infra/environment';
    return this.httpClient.get(
      url, {
      headers: new HttpHeaders().set('Authorization', sessionStorage.getItem('authToken'))
    }).pipe(
      tap((res) => {
        this.isLoadingService.remove();
      }),
      catchError((error) => {
        this.isLoadingService.remove();
        return of(error);
      })
    );
  }

  public getTenantSites() {
    const url = environment.appConfig.multiEnvEndpoint + '/v2/tenant/sites';
    return this.httpClient.get<GetTenantSitesResponse>(url, {
      headers: { Authorization: sessionStorage.getItem('authToken') },
    }).pipe(
      catchError((_: HttpErrorResponse) => {
        return of(null);
      })
    )
  }

  public getUserFederatedGroups() {
    this.isLoadingService.add();
    let url = environment.appConfig.multiEnvEndpoint + '/v2/userinfo';
    return this.httpClient.get(
      url, {
      headers: new HttpHeaders().set('Authorization', sessionStorage.getItem('authToken'))
    }).pipe(
      tap((res) => {
        this.isLoadingService.remove();
      }),
      catchError((error) => {
        this.isLoadingService.remove();
        return of(error);
      })
    );
  }

  /**************************************
        UTILITIES
    **************************************/

  private handleError(error: any): Observable<any> {
    if (this.blockerService) {
      this.blockerService.hide();
    }
    return ObservableThrowError(error.message || error);
  }

  // Utility function to add a version to the endpoint, if necessary
  private versionEndpoint(ssoVersion: number, endpoint: string): string {
    if (ssoVersion && ssoVersion > 1) {
      return endpoint.replace('/auth/', '/auth/ma/');
    } else {
      return endpoint;
    }
  }

}