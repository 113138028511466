import { Component, OnInit, Input } from "@angular/core";

import { environment } from "../../../../../../environments/environment";
import { UserInfoService } from "app/services/user-info.service";
import { first, forkJoin, map, mergeMap, of } from "rxjs";
import { SSOService } from "app/services/sso.service";
import { ProvisionerService } from "app/services/provisioner.service";

@Component({
  selector: "app-additionalresourcecard",
  templateUrl: "./additionalresourcecard.component.html",
  styleUrls: ["./additionalresourcecard.component.scss"],
})
export class AdditionalresourcecardComponent implements OnInit {
  @Input() list;
  localEnvironment: typeof environment;

  fileTransferEnabled = false;
  fileTransferUrl: string;

  constructor(
    private ssoService: SSOService,
    private userInfoService: UserInfoService,
    private provisionerService: ProvisionerService
  ) {
    this.localEnvironment = environment;
  }

  ngOnInit(): void {
    this.checkIfBmftEnabled();
  }

  checkIfBmftEnabled() {
    this.userInfoService.authToken$
      .pipe(
        first((val) => !!val.authToken && !!val.decodedToken),
        // Find site with SFTP configuration with user email
        mergeMap(({ decodedToken }) =>
          this.ssoService.getTenantSites().pipe(
            map((sites) => {
              const email = (decodedToken as any).email;
              const envs = sites?.filter((site) =>
                site.applications?.some((app) =>
                  app.environments?.some((env) => env.sftp?.users[email])
                )
              );
              return envs?.[0]?.envCode;
            })
          )
        ),
        // Get SFTP url from provisioner Lookups
        mergeMap((envCode) => {
          if (!envCode) {
            return of(null);
          }
          return forkJoin([
            this.provisionerService.getSatelliteLookup(),
            this.provisionerService.getUrlLookups(),
          ]).pipe(
            map(([satellites, urls]) => {
              const satellite = satellites?.find(
                (sat) => sat.envCode === envCode
              );
              const url = urls?.find((u) => u._id === satellite?.hub);
              return url?.BmftWebUi;
            })
          );
        })
      )
      .subscribe((url) => {
        this.fileTransferEnabled = !!url;
        this.fileTransferUrl = url;
      });
  }
}
